.card-instructor {
  background-color: #e0e6f5;
  .instructors-table {
    background-color: #fff;
    .instructor-name {
      vertical-align: middle;
    }
  }
}

.card {
  .card-image-trainee {
    width: 6em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 1em;
  }
}

.panel-actions {
  position: fixed;
  left: .5rem;
  right: .5rem;
  bottom: .5rem;
  .btn {
    padding: .75rem;
    i {
      margin-right: .5rem;
      font-size: 1rem;
    }
  }

}

.trainee-control {
  padding: .5rem 0;
  border-bottom: 1px solid #f4f4f4;
  input.form-check-input {
    width: 2.5em;
    height: 1.5em;
    &:checked {
      background-color: var(--bs-success);
      border-color: var(--bs-success);
    }
  }
  .form-check-label {
    padding: 3px 0 0 1rem;
  }
}

.trainee-image {
  max-height: 100px;
}