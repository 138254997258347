
@use 'sass:color';

@import './variables.scss';

@import './globals/globals.scss';
@import './globals/table-calendar.scss';
@import './globals/select2.scss';

@import './formateurs/layout.scss';
// @import './formateurs/login.scss';
@import './formateurs/planner.scss';
@import './formateurs/sessions.scss';
@import './formateurs/actions.scss';
@import './formateurs/test.scss';

// globals

input[type=text], input[type=password], select, textarea {
  background-color: white !important;
}

a.btn-div, div.btn-div {
  display: block;
  color: $body-color;
  text-decoration: none;
  cursor: pointer;
  padding: .5rem;
  border-radius: var(--bs-border-radius);
  &:hover {
    color: color.adjust($body-color, $lightness: -10%);
    background-color: #eee;
  }
}

.select2-container--open{
  z-index:9999999;
}
