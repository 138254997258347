
body {
  
  nav.bg-dark {
    background-color: $secondary !important;

    .btn {
      padding: .5rem .75rem;
      i {
        font-size: 18px;
      }
    }
  }

  .container main {
    max-width: 414px;
    margin: auto;
  }
}

footer {
  padding: 3rem 0 1rem;
}
