
.accordion-button {
  color: var(--bs-accordion-btn-color) !important;
  background-color: var(--bs-accordion-btn-bg) !important;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}

.criteria-rating {
  .criteria-input-container {
    &:not(:first-child) {
      padding-left: .6rem;
    }
    padding-right: .6rem;
  }
}
input[type=radio].lg-radio {
  width: 28px;
  height: 28px;
}

.test-theme-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: var(--bs-gray-200);
  border-top: 1px solid var(--bs-gray-600);
  // height: 60px;
  padding: .8rem;

  .bottom-container {
    max-width: 414px;
    margin: auto;
  }
}

.fs-55 {
  font-size: 1rem;
}