
.absent {
  text-align: center;
  margin: 1rem .5rem;
  font-weight: bold;
  color: var(--bs-danger);
}


// SIGNATURE PAD
.signature-box {
  text-align: center;
  .box {
    position: relative;
    width: 100%;
    min-height: 155px;
    cursor: pointer;
    border: 1px dashed #ccc;
    border-radius: 4px;
    margin: auto;
    background-color: white;
  }

  img {
    border-radius: 4px;
  }

  .nosignature {
    text-align: center;
    padding: .5rem;
    margin-top: 40px;
  }
}

// modal
#signature-zone {
  width: 100%;
  height: 100%;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  padding: 16px;
}

#signature-pad {
  position: relative;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 1px solid #f4f4f4;
}

#signature-canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}


// SPINNER
.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .6);
  > div {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 3rem auto;
  }

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
}

.no-icon-accordion::after {
  all: unset;
}

table {
  .table-is-today {
    color: #333;
    background-color: #fff0d3;
  }
}

.toggle-qcm-number {
  display: flex;
  align-items: center;
}