#formateur-planner {
  a {
    color: inherit;
    text-decoration: none;
  }

  .card {
    text-align: center;
  
    .card-header {
      background-color: $gray;
      color: #fff;
      justify-content: center;
      text-transform: capitalize;
    }
  
    .badge-period {
      text-transform: uppercase;
      font-size: 0.7rem;
      color: $gray;
    }
  
    .card-body {
      padding: 0;
    }
  }

  .session-semicard {
    position: relative;
    padding: .5rem; 

    &:hover {
      background-color: #eee;
      transition: 0.3s;
    }
  }

  .today {
    border: 1px solid $secondary;
    .card-header {
      background-color: $secondary;
      color: white;
      font-size: 1.1rem;
    }
  }
}

.session-title {
  font-size: 0.95rem;
  font-weight: bold;
  text-transform: uppercase;
  color: $primary;
}